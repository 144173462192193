import { Button, Carousel } from 'antd'
import React from 'react'
import { BASE_URL } from '../../constants/Url'
import { PiHairDryerFill } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import BeautyIcon from '../../assets/icons/Beauty';
import CleaningIcon from '../../assets/icons/Cleaning';
import MenSalonIcon from '../../assets/icons/MenSalon';
import PlayIcon from '../../assets/icons/Play';
import DownloadApp from '../../components/DownloadApp';

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <div className='w-full'>
      <Carousel className='w-full esi-carousel-banner' draggable dots pauseOnDotsHover pauseOnHover autoplay>
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-1.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-2.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-3.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-4.png' />
      </Carousel>

      <div className='esi-white-space'></div>

      <div className='esi-std-container'>

        {/* about us */}
        <div className='md:grid grid-cols-5 gap-3 flex flex-col-reverse'>
          <div className='px-[20px] sm:px-0 max-w-[640px] pt-5 pb-5 sm:pb-10 col-span-3 flex flex-col gap-3'>
            <div className='esi-content-title'>
              {t("Content About Us")}
              <div className='esi-secondary-line'></div>
            </div>

            <div className='flex flex-col gap-4 mt-3'>
              <div className='esi-content-paragraph'>ESTETICA is the world’s leading hair trade magazine founded in Tornio, Italy in 1946 with 18 editions worldwide in different local language.</div>
              <div className='esi-content-paragraph'>{t("ESTETICA is the world’s leading hair trade magazine founded in Tornio, Italy in 1946 with 18 editions worldwide in different local language. ")}</div>
              <div className='esi-content-paragraph'>ESTETICA 艾絲 was first published in Mainland China 2004. It is a bridge for East and West professional hair industry to link up with global platforms and markets.</div>
              <div className='esi-content-paragraph'>{t("ESTETICA 艾絲 was first published in Mainland China 2004. It is a bridge for East and West professional hair industry to link up with global platforms and markets. ")}</div>
            </div>
            <div className='mb-0 mt-auto'>
              <Button className='esi-std-button'>
                {t("Contact Us")}
              </Button>
            </div>
          </div>
          <div className='col-span-2'>
            <img src={BASE_URL + "/about-us/About Us.png"} className='esi-image' alt='ESI About Us' />
          </div>
        </div>

        <div className='esi-white-space'></div>

        <div className='md:grid grid-cols-5 gap-3 flex flex-col'>
          <div className='col-span-2'>
            <img src={BASE_URL + "/about-us/What We Do.png"} className='esi-image' alt='ESI What we do' />
          </div>
          <div className='pt-5 px-[20px] sm:px-[70px] pb-0 sm:pb-10 col-span-3 flex flex-col gap-3'>
            <div className='esi-content-title'>
              {t("What We Do")}
              <div className='esi-secondary-line'></div>
            </div>

            <div className='esi-content-paragraph mt-3'>A multi-media hair and beauty platform for beauty professionals to get access to global market news, technic and business management knowledge.</div>
            <div className='esi-content-paragraph'>{t("A multi-media hair and beauty platform for beauty professionals to get access to global market news, technic and business management knowledge.")}</div>
            <div className='esi-content-paragraph'>With the international networks of Estetica, we can provide a global stage for products and hairstylists promotion. Full force to promote the development of hair beauty industry.</div>
            <div className='esi-content-paragraph mb-3'>{t("With the international networks of Estetica, we can provide a global stage for products and hairstylists promotion. Full force to promote the development of hair beauty industry.")}</div>

            <div className='mb-0 mt-auto flex justify-between'>
              <div className='flex flex-col gap-3 items-center'>
                <BeautyIcon className='esi-icons' color='#CABDFF' backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("HAIR-NEWS")}</div>
              </div>

              <div className='flex flex-col gap-3 items-center'>
                <MenSalonIcon className='esi-icons' color="#AFC6FF" backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("TRENDS")}</div>
              </div>

              <div className='flex flex-col gap-3 items-center'>
                <CleaningIcon className='esi-icons' color="#FFD88D" backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("EDUCATION")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='esi-download-app-home mt-4'>
          <DownloadApp className="h-full"
            title={
              <div className='esi-content-title'>
                {t("Download App")}
                <div className='esi-secondary-line'></div>
              </div>
            }
          />
        </div>

        <div className='esi-white-space'></div>

        <div className='relative px-[20px] sm:px-0'>
          <div className='esi-play-icon-wraper'>
            <div className='esi-play-icon-container relative'>
              <div className='esi-play-icon-blur'></div>
              <PlayIcon className='esi-play-icon' color='white' />
            </div>
          </div>
          <img src={BASE_URL + "/about-us/video.png"} className='esi-responsive-iframe' />
        </div>

        <div className='esi-white-space'></div>
      </div>
    </div>
  )
}
