import { List, Skeleton } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MAGAZINE } from '../../constants/Url';
import AppDownloadModal from '../../components/AppDownloadModal';
import httpClient from '../../utils/HttpClient';
import { objectToQuery } from '../../utils/Utils';
import { debounce } from 'lodash';

export default function MagazinePage() {
    const appDownloadRef = useRef();
    const [loading, setLoading] = useState(true);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(36);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState([
        // DO NOT REMOVE THIS COMMENT
        // {
        //     "previewFile": {
        //         "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        //         "size": 1222,
        //         "fileName": "1732153454621-9kb.jpg",
        //         "page": 10,
        //         "uploadedBy": "673e8d6f2e4aa6a2c804810b",
        //         "uploadedTS": "2024-11-27T12:01:10.827Z"
        //     },
        //     "_id": "674709f69524e08dce0b8c53",
        //     "magazineId": "MZ16800007",
        //     "title": "Magazine 7",
        //     "picUrl": "https://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1734365508258-image-6.png",
        //     "id": "674709f69524e08dce0b8c53"
        // }
    ]);

    const handleShowDownloadApp = () => {
        appDownloadRef.current?.showModal();
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const query = {
                pageNo: pageNo,
                pageSize: pageSize
            };

            const res = await httpClient.get(objectToQuery(MAGAZINE, query))
                .then(res => res.data)
                .catch(error => ({ status: 500, message: error?.message }));

            if (res.status === 200) {
                setDataSource([...res.data])
                setTotal(res.total);
            } else {
                setDataSource([]);
                setTotal(0);
            }
        } catch (error) {
            console.error("fetchData -> error", error)
        } finally {
            setLoading(false);
        }
    }

    const debouncedFetchData = useCallback(
        debounce(fetchData, 300),
        [pageNo, pageSize]
    );


    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel debounce on unmount or dependency change
        return debouncedFetchData.cancel;
    }, [debouncedFetchData])

    return (
        <div className='esi-std-container full-flex pb-[40px]'>
            <List
                className="esi-std-white-space full-flex"
                rootClassName='loading-full-flex'
                grid={{
                    gutter: [25, 15],
                    xs: 2,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                pagination={{
                    className: "esi-std-pagination",
                    pageSize: pageSize,
                    total: loading ? 8 : total,
                    current: pageNo,
                    onChange: (page, limit) => {
                        if (pageSize !== limit) {
                            setPageSize(limit);
                            setPageNo(1);
                        } else {
                            setPageNo(page);
                        }
                    },
                    pageSizeOptions: [1, 2, 3, 4, 5].map((item) => item * 12),
                }}
                dataSource={loading ? Array(8).fill(0) : dataSource}
                renderItem={(item) => (
                    loading ?
                        <List.Item>
                            <div className="select-none	text-center gap-3 flex flex-col">
                                <div className="overflow-hidden">
                                    <Skeleton.Image rootClassName='esi-card-image' style={{ cursor: "pointer" }} className='esi-card-image' active={true} />
                                </div>
                                <Skeleton.Input className="esi-card-title max-w-[100%] truncate" active block />
                            </div>
                        </List.Item>
                        :
                        <List.Item>
                            <div className="select-none	text-center gap-3 flex flex-col">
                                <div
                                    className="overflow-hidden"
                                    onClick={handleShowDownloadApp}
                                >
                                    <img className="esi-card-image" style={{ cursor: "pointer" }} src={item.picUrl} alt={item.previewFile?.fileName} />
                                </div>
                                <div className="esi-card-title max-w-[100%] truncate">{item.title}</div>
                            </div>
                        </List.Item>
                )}
            ></List>
            <AppDownloadModal ref={appDownloadRef} />
        </div>
    )
}
