import './App.css';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import DefaultLayout from './layouts/DefaultLayout';
import MagazinePage from './pages/magazine/MagazinePage';
import NewsPage from './pages/new/NewsPage';
import NewsDetailPage from './pages/new/NewsDetailPage';
import NotFoundPage from './pages/NotFoundPage';
// import EducatePage from './pages/EducatePage';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/magazine' element={<MagazinePage />} />
          <Route path='/news' element={<NewsPage />} />
          <Route path='/news/:code' element={<NewsDetailPage />} />
          {/* <Route path='/education' element={<EducatePage />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
