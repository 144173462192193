import { Button, Carousel, List, Skeleton } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { NEWS, NEWS_CATEGORY } from '../../constants/Url';
import { useNavigate } from 'react-router';
import { antdResponsive, objectToQuery, showFormatDate } from '../../utils/Utils';
import TextScroll from '../../components/TextScroll';
import { useTranslation } from 'react-i18next';
import httpClient from '../../utils/HttpClient';
import { debounce } from 'lodash';

export default function NewsPage() {
    const navigate = useNavigate();
    // const appDownloadRef = useRef();
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [total, setTotal] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [blogLoading, setBlogLoading] = useState(true);
    const [blogMainLoading, setBlogMainLoading] = useState(true);
    const [blogs, setBlogs] = useState([
        // DO NOT REMOVE THIS COMMENT
        // TO KEEP TRACK OF THE DATA STRUCTURE
        // {
        //     coverimage: "",
        //     title: "",
        //     subtitle: "",
        //     category: "",
        //     createdAt: "",
        // }
    ]);
    const [blogMain, setBlogMain] = useState([
        // DO NOT REMOVE THIS COMMENT
        // TO KEEP TRACK OF THE DATA STRUCTURE
        // {
        //     coverimage: "",
        //     title: "",
        //     subtitle: "",
        //     category: "",
        //     createdAt: "",
        // }
    ]);
    const [category, setCategory] = useState([
        // DO NOT REMOVE THIS COMMENT
        // TO KEEP TRACK OF THE DATA STRUCTURE
        // {
        //     name: "",
        //     _id: "",
        // }
    ]);

    const fetchBlog = async (query) => {
        setBlogLoading(true);
        try {
            const res = await httpClient.get(objectToQuery(NEWS, query ?? { pageNo, pageSize, category: selectedCategory }))
                .then(res => res.data)
                .catch(error => ({ status: 500, message: error?.message }));

            if (res.status === 200) {
                setBlogs([...res.data])
                setTotal(res.total);
            } else {
                setBlogs([]);
            }
        } catch (error) {
            console.error("fetchData -> error", error)
        } finally {
            setBlogLoading(false);
        }
    }

    const fetchCategories = async () => {
        setCategoryLoading(true);
        try {
            const res = await httpClient.get(NEWS_CATEGORY)
                .then(res => res.data)
                .catch(error => ({ status: 500, message: error?.message }));

            if (res.status === 200) {
                setCategory([...res.data])
            } else {
                setCategory([]);
            }
        } catch (error) {
            console.error("fetchData -> error", error)
        } finally {
            setCategoryLoading(false);
        }
    }

    const fetchMainBlog = async () => {
        setBlogMainLoading(true);
        try {
            const res = await httpClient.get(objectToQuery(NEWS, { pageNo: 1, pageSize: 10 }))
                .then(res => res.data)
                .catch(error => ({ status: 500, message: error?.message }));

            if (res.status === 200) {
                setBlogMain([...res.data])
            } else {
                setBlogMain([]);
            }
        } catch (error) {
            console.error("fetchData -> error", error)
        } finally {
            setBlogMainLoading(false);
        }
    }

    const handleChangeCategory = (item) => {
        setSelectedCategory(item.name === selectedCategory ? "" : item.name);
        setPageNo(1);
    }

    const openDetailsPage = (item) => {
        navigate(`/news/${item.newsId}`);
    }

    const debouncedFetchAll = useCallback(
        debounce(async () => {
            await Promise.all([fetchCategories(), fetchMainBlog()])
        }, 300),
        []
    );

    const debouncedFetchBlogCallback = useCallback(
        debounce(() => fetchBlog({ pageNo, pageSize, category: selectedCategory }), 300),
        [pageNo, pageSize, selectedCategory]
    );

    useEffect(() => {
        debouncedFetchBlogCallback();
        // Cleanup to cancel debounce on unmount or dependency change
        return debouncedFetchBlogCallback.cancel;
    }, [debouncedFetchBlogCallback])

    useEffect(() => {
        debouncedFetchAll();
        // Cleanup to cancel debounce on unmount or dependency change
        return debouncedFetchAll.cancel;
    }, [debouncedFetchAll])

    return (
        <div className='esi-std-container full-flex'>
            <Carousel
                className='esi-std-white-space esi-news-main-carousel'
                responsive={antdResponsive({
                    xxs: {
                        slidesToShow: 1,
                    },
                    sm: {
                        slidesToShow: 2,
                    },
                })}
                slidesToShow={3}
                draggable
                autoplay
                slidesToScroll={1}
                dots={false}
                swipeToSlide
            >
                {
                    blogMainLoading && Array(4).fill(0).map(item => {
                        return <div className='cursor-pointer select-none esi-news-card'>
                            <div className='overflow-hidden max-sm:rounded-[1rem]'>
                                <Skeleton.Image rootClassName='esi-news-card-image' className='esi-news-card-image' active={true} />
                            </div>
                            <div className='text-start w-full text-white md:text-black absolute left-[24px] bottom-[6px] md:relative md:left-0 md:bottom-0 esi-news-card-title max-w-[calc(100%-24px*2)] md:max-w-[100%] mt-3 line-clamp-2'>
                                <Skeleton.Input active block />
                                <Skeleton.Input active block />
                            </div>
                        </div>
                    })
                }
                {
                    !blogMainLoading && blogMain.map((item) => {
                        return <div className='cursor-pointer select-none esi-news-card' onClick={() => openDetailsPage(item)}>
                            <div className='overflow-hidden max-sm:rounded-[1rem]'>
                                <img className='esi-news-card-image' src={item.coverimage} alt={item.title} />
                            </div>
                            <div className="text-start text-white md:text-black absolute left-[24px] bottom-[6px] md:relative md:left-0 md:bottom-0 esi-news-card-title max-w-[calc(100%-24px*2)] md:max-w-[100%] mt-3 line-clamp-2">
                                {item.title}
                            </div>
                        </div>;
                    })
                }
            </Carousel>

            <TextScroll textButton={t('Latest news')} content={t('After 13 successful years in Spain, Thys Niermeyer is appointed as the new head of ghd North America')} />

            <div className='gap-3 esi-std-white-space flex flex-wrap justify-center md:justify-start'>
                {
                    categoryLoading ?
                        Array(4).fill(0).map(i => <Skeleton.Button className='esi-news-category' active shape={'square'} />)
                        :
                        category.map(item => {
                            return <Button type='text' className={`esi-news-category ${selectedCategory === item.name && "active"}`} onClick={() => handleChangeCategory(item)}>
                                {item.name}
                            </Button>;
                        })
                }
            </div>

            <div className='full-flex'>
                <List
                    className="mt-4 full-flex"
                    rootClassName='loading-full-flex'
                    grid={{
                        gutter: { xs: 10, md: 20, lg: 30 },
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 2,
                        xl: 2,
                        xxl: 2,
                    }}
                    pagination={{
                        className: "esi-std-pagination",
                        pageSize: pageSize,
                        total: blogLoading ? 6 : total,
                        current: pageNo,
                        onChange: (page, limit) => {
                            if (pageSize !== limit) {
                                setPageSize(limit);
                                setPageNo(1);
                            } else {
                                setPageNo(page);
                            }
                        },
                        pageSizeOptions: Array.from({ length: 5 }, (_, i) => i + 1).map((item) => item * 10),
                    }}
                    dataSource={blogLoading ? Array(6).fill(0) : blogs}
                    renderItem={(item) => (
                        blogLoading ?
                            <List.Item>
                                <div className="grid grid-cols-7 cursor-pointer select-none">
                                    <div className="col-span-3 overflow-hidden rounded-[0.5rem]">
                                        <Skeleton.Image rootClassName='esi-sub-news-card-image' style={{ cursor: "pointer" }} className='esi-sub-news-card-image' active={true} />
                                    </div>
                                    <div className='col-span-4 px-3 flex flex-col justify-between'>
                                        <div className='flex flex-col gap-1'>
                                            <Skeleton.Input className="esi-news-card-title max-w-[100%] line-clamp-2" active block />
                                            <Skeleton.Input className="esi-news-card-title max-w-[100%] line-clamp-2" active block />

                                            <Skeleton.Input
                                                rootClassName='max-sm:!hidden md:!block'
                                                className="esi-news-card-description max-w-[100%] line-clamp-2 sm:line-clamp-3" active block />
                                        </div>
                                        <div className='mt-3 lg:mt-0 gap-3 flex justify-between items-center'>
                                            <Skeleton.Button className='esi-secondary-button' active shape={'square'} />
                                            <div className='esi-news-card-dob w-full'>
                                                <Skeleton.Input rootClassName='esi-input-skeleton' active />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                            :
                            <List.Item>
                                <div className="grid grid-cols-7 cursor-pointer select-none" onClick={() => openDetailsPage(item)}>
                                    <div className="col-span-3 overflow-hidden rounded-[0.5rem]">
                                        <img className="esi-sub-news-card-image" style={{ cursor: "pointer" }} src={item.coverimage} alt={item.title} />
                                    </div>
                                    <div className='col-span-4 px-3 flex flex-col justify-between'>
                                        <div className='flex flex-col gap-1'>
                                            <div className="esi-news-card-title max-w-[100%] line-clamp-1 xl:line-clamp-2">{item.title}</div>
                                            <div className="esi-news-card-description max-w-[100%] line-clamp-2 xl:line-clamp-3">{item.subtitle}</div>
                                        </div>
                                        <div className='mt-3 lg:mt-0 flex justify-between items-center'>
                                            <Button className='esi-secondary-button'>{item.category}</Button>
                                            <div className='esi-news-card-dob'>{showFormatDate(item.createdAt)}</div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                    )}
                ></List>
            </div>

            <div className='h-[40px]'></div>
        </div>
    )
}
