import { Collapse, Typography } from 'antd';
import React from 'react'

export default function DynamicLayout(props) {
    const { data } = props;

    const style = {
        fontWeight: data?.fontWeight === "bold" ? "bold" : "regular",
        fontStyle: data?.italicType === "italic" ? "italic" : "normal",
        textDecoration: data?.textDecoration,
        textAlign: data?.textAlign,

        marginBottom: data?.marginBottom ?? "0px",
        ...data?.marginTop ? { marginTop: data.marginTop } : {},
        ...data?.marginLeft ? { marginLeft: data.marginLeft } : {},
        ...data?.marginRight ? { marginRight: data.marginRight } : {},

        ...data?.width ? { width: data.width } : {},
        ...data?.height ? { height: data.height } : {},
    };

    const getContent = () => {
        return <p style={style}>{data?.text}</p>
    }

    const getLink = () => {
        return <Typography.Link
            style={style}
            href={data.link}
            target="_blank"
        >
            {data?.text}
        </Typography.Link>
    }

    const getBullet = () => {
        return <ul className='ul' style={{ paddingLeft: "35px" }}>
            <li className='li' style={style}>
                {data?.text}
            </li>
        </ul>
    }

    const getTitle = (level) => {
        return <Typography.Title
            className={`h${level}`}
            style={style}
            level={level}
        >{data?.text}</Typography.Title>
    }

    const getImage = () => {
        return <img
            className='w-full rounded-lg object-cover'
            style={{
                ...style,
                aspectRatio: "4/2",
            }}
            src={data?.url}
            alt={data?.url}
        />
    }

    const getVideo = () => {
        return <video className='w-full' key={data.url + data.type} controls style={{ width: "100%" }}>
            <source src={data.url} type={data.type} />
            Your browser does not support the video tag.
        </video>
    }

    const handleAccordin = () => {
        return <Collapse
            items={[{
                key: data?.index,
                label: `${data?.text}`,
                children: data.accordion.map(item => <DynamicLayout data={item} />),
            }]}
        />;
    }

    const handleType = (type) => {
        switch (type) {
            case "link":
                return getLink();
            case "content":
                return getContent();
            case "h1":
                return getTitle(1);
            case "h2":
                return getTitle(2);
            case "h3":
                return getTitle(3);
            case "h4":
                return getTitle(4);
            case "h5":
                return getTitle(5);
            case "bullet":
                return getBullet();
            case "image":
                return getImage();
            case "video":
                return getVideo();
            case "accordion":
                return handleAccordin();
            default:
                break;
        }
        return "";
    }

    return (
        <div key={data?.index}>
            {handleType(data?.tag)}
        </div>
    )
}
