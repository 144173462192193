import _, { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import NotFoundPage from '../NotFoundPage';
import "./styles/NewsDetailPage.css";
import { showFormatDate } from '../../utils/Utils';
import { NEWS } from '../../constants/Url';
import { Button, Skeleton } from 'antd';
import httpClient from '../../utils/HttpClient';
import DynamicLayout from './components/DynamicLayout';

export default function NewsDetailPage() {
  const [data, setData] = useState({
    coverimage: "",
    title: "",
    subtitle: "",
    category: "",
    createdAt: "",
  });
  const [notFound, setNotFound] = useState(false);
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const fetchData = async () => {
    try {
      setLoading(true);

      const res = await httpClient.get(NEWS + `/${code}`)
        .then(res => res.data)
        .catch(error => ({ status: 500, message: error?.message }));

      if (res.status === 200) {
        setNotFound(false);
        setData({ ...res.data })
      } else {
        setNotFound(true);
      }
    } catch (error) {
      console.error("fetchData -> error", error)
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  }

  const debouncedFetchData = useCallback(
    debounce(fetchData, 300),
    []
  );


  useEffect(() => {
    debouncedFetchData();
    // Cleanup to cancel debounce on unmount or dependency change
    return debouncedFetchData.cancel;
  }, [location, code, debouncedFetchData])

  return (
    <div className='esi-std-container w-full'>
      {
        loading && <div className='esi-std-white-space flex flex-col gap-3 w-full'>
          <Skeleton.Image rootClassName='news-image w-full' className='news-image' active={true} />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <div className='flex items-center justify-between'>
            <Skeleton.Button className='news-primary-button' active shape={'default'} />

            <Skeleton.Input className="news-published-date" active />
          </div>

          <div className='flex flex-col gap-[4px]'>
            <Skeleton.Input active block />
            <Skeleton.Input active block />
            <Skeleton.Input active block />
          </div>
        </div>
      }

      {
        !loading && notFound ?
          <NotFoundPage />
          :
          <div className='esi-std-white-space flex flex-col gap-3'>
            {data.coverimage && <img className='news-image' src={data.coverimage} alt={data.title} />}
            <h1 className='h1'>{data && data.title}</h1>
            <div className='news-summary'>{data && data.subtitle}</div>
            <div className='flex items-center justify-between'>
              <Button className='news-primary-button'>{data && data.category}</Button>
              <div className='news-published-date'>{!_.isEmpty(data?.createdAt) && showFormatDate(data.createdAt)}</div>
            </div>

            <div className='flex flex-col gap-[4px]'>
              {
                data && data?.layouts?.map(item => <DynamicLayout data={item} />)
              }
            </div>
          </div>
      }

      <div className='esi-std-white-space'></div>
    </div>
  )
}
